<script>
import BaseListing from '../ListingWithEditMode'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Widoczność nagłówków', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: `${this.service}_grid_appraisal_header_visibility`,
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'header', title: 'Nazwa nagłówka' },
        { id: 1, data: 'role', title: 'Rola' }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_header-edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:HeaderVisibilityEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/headers/visibility/all` }
    }
  }
}
</script>
